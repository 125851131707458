@import url(https://fonts.googleapis.com/css?family=Montserrat:300,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=EB+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
:root {
  --main-color: #00FFFF;
  --mont: Montserrat, sans-serif;
  --karla: Karla, sans-serif;
  --garamond: EB, serif;
  --oswald: Oswald, serif;
  --shadow: 2px 4px 4px rgba(0,0,0,0.3); }

body {
  background: #010101f7; }

html, body, #root {
  height: 100%; }

#root {
  height: 100%;
  width: 100%; }
  @media only screen and (max-width: 1023px) {
    #root {
      width: 100%; } }
  #root.modal-on {
    -webkit-filter: brightness(0.2);
            filter: brightness(0.2);
    pointer-events: none;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear; }

.i-am {
  background-position: center center;
  background-size: cover;
  -webkit-transition: all .5s linear;
  transition: all .5s linear;
  -webkit-transform: translate3d(0, 0, 0); }
  .i-am--desktop {
    font-family: Montserrat, sans-serif;
    font-family: var(--mont);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    height: 100%;
    overflow-y: scroll; }
  .i-am--mobile {
    font-family: Montserrat, sans-serif;
    font-family: var(--mont);
    display: grid;
    background-repeat: no-repeat;
    background-attachment: fixed; }
  .i-am--statements {
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    grid-template-areas: '. content .';
    align-items: stretch; }
  .i-am__header-container {
    display: grid;
    grid-gap: 15px;
    align-items: center;
    grid-area: header; }
  .i-am__body {
    font-family: 'EB Garamond', serif;
    text-align: justify;
    font-size: .8rem;
    grid-area: body;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    align-items: center;
    color: white;
    text-shadow: 2px 4px 4px rgba(0,0,0,0.3);
    text-shadow: var(--shadow); }
    .i-am__body h3 {
      font-weight: 400;
      margin: 0;
      font-size: .9rem;
      text-align: center; }
    .i-am__body p {
      font-size: 1rem;
      margin: 0;
      line-height: 1.6rem; }
  .i-am__header-verse {
    font-family: Montserrat, sans-serif;
    font-family: var(--mont);
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.025rem;
    word-spacing: 0.2rem;
    line-height: 1.4rem;
    text-shadow: 2px 4px 4px rgba(0,0,0,0.3);
    text-shadow: var(--shadow);
    margin: 0;
    text-align: center; }
  .i-am__header-passage {
    font-family: Montserrat, sans-serif;
    font-family: var(--mont);
    font-weight: 300;
    color: #00FFFF;
    color: var(--main-color);
    margin: 0;
    text-align: center;
    font-size: 0.6rem;
    letter-spacing: 0.15rem;
    word-spacing: 0.2rem;
    text-shadow: 2px 4px 4px rgba(0,0,0,0.3);
    text-shadow: var(--shadow); }
  .i-am__button-group {
    grid-area: buttons;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  @media only screen and (max-width: 1023px) {
    .i-am {
      width: 100%;
      overflow-y: scroll;
      height: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      grid-row-gap: 20px; }
      .i-am--home {
        grid-template-columns: 15% 1fr 15%;
        grid-template-areas: ". . ." ". header ." ". body ." ". buttons ." ". . .";
        grid-template-rows: 5% 1fr 1fr 1fr 5%; }
      .i-am--statements {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        justify-items: center;
        align-items: center; }
      .i-am--commands {
        grid-template-columns: 8% 1fr 8%;
        grid-template-areas: ". section ."; }
        .i-am--commands .i-am-commands__number {
          height: 26px;
          bottom: -13px;
          width: 26px; }
        .i-am--commands .section {
          padding-bottom: 60px; }
        .i-am--commands .section__header {
          color: #fff;
          margin-top: 20%;
          margin-bottom: 40px;
          font-size: 1.5rem; }
      .i-am__header-container {
        justify-items: center; }
      .i-am__header-verse {
        font-size: .8rem;
        text-align: center;
        letter-spacing: 0.025rem;
        word-spacing: 0.2rem;
        line-height: 1.4rem;
        text-shadow: 2px 4px 4px rgba(0,0,0,0.3);
        text-shadow: var(--shadow); } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 896px) and (orientation: landscape) {
    .i-am--statements {
      display: grid;
      grid-template-columns: 5% 1fr 5%;
      grid-template-areas: '. content .';
      align-items: stretch; }
      .i-am--statements .i-am-all-statements-commands__statement-container {
        grid-template-columns: repeat(4, 1fr); }
        .i-am--statements .i-am-all-statements-commands__statement-container .i-am-button {
          margin: 0; }
      .i-am--statements .i-am-all-statements-commands__associated-commands .i-am-commands__title {
        font-size: .8rem; }
      .i-am--statements .i-am-all-statements-commands__associated-commands .i-am-commands__number {
        height: 2rem;
        width: 2rem;
        bottom: -1rem;
        font-size: .8rem; }
    .i-am__header-container {
      display: flex;
      flex-direction: column;
      height: 80vh;
      justify-content: space-evenly; }
      .i-am__header-container .i-am-logo {
        display: flex;
        height: 80px; }
      .i-am__header-container .i-am-button {
        width: auto;
        padding: 0 20px;
        height: 40px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .i-am__header-container {
      height: 60vh; } }
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .i-am__header-container {
      height: 40vh; } }
  @media (min-width: 1024px) {
    .i-am__header-container {
      grid-gap: 40px;
      grid-template-areas: "header" "body" "button"; }
    .i-am__logo {
      display: flex;
      justify-content: center; }
    @-moz-document url-prefix() {
      .i-am__logo {
        width: 50%;
        margin: auto; } }
    .i-am .i-am-logo {
      height: 100%;
      width: 80%; }
    .i-am .i-am-button {
      margin: 0;
      width: auto;
      justify-self: center;
      padding: 0 40px;
      font-weight: 600; } }

.i-am-statements {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .i-am-statements__header {
    color: white;
    display: flex;
    justify-content: center;
    font-weight: 300;
    letter-spacing: 0.25rem;
    word-spacing: 0.2rem;
    grid-area: header; }
    .i-am-statements__header .i-am-button {
      width: 250px;
      height: 50px; }
  .i-am-statements.show-commands {
    display: grid;
    grid-row-gap: 20px;
    width: 100%;
    height: 100%;
    grid-template-columns: 22% 1fr 22%;
    grid-template-areas: "header header header" ". section ." "faith faith faith"; }
  @media only screen and (max-width: 1023px) {
    .i-am-statements__header {
      margin-top: 20px; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 896px) and (orientation: landscape) {
    .i-am-statements {
      grid-area: content; } }

.i-am-faith-statement {
  grid-area: faith;
  display: grid;
  grid-template-columns: 15% 1fr 15%;
  grid-template-areas: ". faith-header ." "faith-body faith-body faith-body";
  margin: 80px 0; }
  .i-am-faith-statement__header {
    background: #00FFFF;
    background: var(--main-color);
    font-weight: 600;
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 5px;
    z-index: 1;
    height: 40px;
    font-family: Montserrat, sans-serif;
    font-family: var(--mont);
    grid-area: faith-header; }
  .i-am-faith-statement__body {
    z-index: 2;
    grid-area: faith-body;
    background: #00000070;
    color: #fff;
    padding: 10%;
    font-size: .9rem;
    text-align: center;
    line-height: 2; }

.i-am-button {
  cursor: pointer;
  border: none;
  font-size: 0.8rem;
  font-family: Montserrat, sans-serif;
  font-family: var(--mont);
  font-weight: 100;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.15rem;
  word-spacing: 0.2rem;
  background: #00FFFF;
  background: var(--main-color);
  color: black;
  position: relative;
  margin: 5px 0; }
  .i-am-button .statement-box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .i-am-button--transparent {
    color: white;
    background: transparent;
    border: 1px solid #00FFFF;
    border: 1px solid var(--main-color); }
  .i-am-button--transparent-title {
    color: white;
    background: transparent;
    border: 1px solid #00FFFF;
    border: 1px solid var(--main-color);
    font-weight: 600;
    letter-spacing: 0.05rem;
    word-spacing: 0.1rem; }
  .i-am-button--full-border {
    background: #00000080;
    font-weight: 600;
    border: 1px solid #00FFFF;
    border: 1px solid var(--main-color);
    color: white; }

.i-am-modal {
  color: #fff;
  z-index: 9999; }
  .i-am-modal__container {
    width: 80vw;
    max-height: 70vh;
    border: 1px solid #00FFFF;
    border: 1px solid var(--main-color);
    padding: 20px 20px 40px 20px;
    background: #00000090;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center; }
  .i-am-modal__passage {
    font-family: Montserrat, sans-serif;
    font-family: var(--mont);
    font-weight: 300;
    font-size: .8rem;
    line-height: 1.5rem;
    overflow: auto;
    letter-spacing: 0.025rem; }
  .i-am-modal__passage-reference {
    text-transform: uppercase;
    padding: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00FFFF;
    background: var(--main-color);
    color: #000;
    position: absolute;
    bottom: -10px;
    width: 80%;
    height: 20px;
    font-family: Karla, sans-serif;
    font-family: var(--karla);
    letter-spacing: 0.2rem;
    font-size: .9rem; }
  @media (min-width: 1024px) {
    .i-am-modal__container {
      width: 50vw; }
    .i-am-modal__passage-reference {
      width: 40%; } }

.section {
  grid-area: section; }
  .section__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
    width: 100%;
    grid-template-rows: 1fr;
    grid-row-gap: 2.5rem;
    grid-column-gap: 8px; }
  .section__header {
    display: flex;
    justify-content: center;
    letter-spacing: .3rem; }

.i-am-commands {
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #00000070;
  color: #fff;
  font-size: 12px;
  padding: 1rem 1rem 2rem 1rem; }
  .i-am-commands.selected .i-am-commands__number {
    color: white;
    border: 1px solid #00FFFF;
    border: 1px solid var(--main-color);
    background: #000; }
  .i-am-commands:before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(4 / 3 * 100%); }
  .i-am-commands__title {
    display: flex;
    align-items: flex-start;
    padding-top: 3px;
    text-align: center;
    justify-content: center;
    line-height: 1.4; }
  .i-am-commands__number {
    position: absolute;
    bottom: -20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #000;
    background: #00FFFF;
    background: var(--main-color);
    justify-content: center;
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 700; }
  .i-am-commands.fortynine-commands:before {
    padding-bottom: calc(4 / 2.4 * 100%); }

.i-am-logo__path {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .82px; }

.statement-box__path {
  fill: none;
  stroke: #0ff;
  stroke-miterlimit: 10;
  stroke-width: 2.25px; }

.fab-button {
  cursor: pointer;
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
  background: #00FFFF;
  background: var(--main-color);
  border-radius: 50%;
  border: 1px solid #000;
  height: 50px;
  width: 50px;
  position: fixed;
  z-index: 500;
  bottom: 40px;
  right: 40px; }
  @media (min-width: 1024px) {
    .fab-button {
      bottom: 80px;
      right: 80px;
      height: 60px;
      width: 60px; } }

.background-image {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1; }
  .background-image__container {
    height: 100%;
    width: 100%;
    position: relative; }
  .background-image .img-responsive {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* ending EXIT animation */ }
    .background-image .img-responsive:nth-child(2n) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
    .background-image .img-responsive.picture-exit {
      opacity: 1; }
    .background-image .img-responsive.picture-exit-active {
      opacity: 0;
      -webkit-transition: opacity 1000ms ease-in-out;
      transition: opacity 1000ms ease-in-out; }

.i-am-all-statements-commands {
  grid-row-gap: 40px;
  display: grid;
  grid-area: content;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: "blurb" "statements" "commands";
  padding: 60px 0; }
  .i-am-all-statements-commands.current-statement {
    grid-template-areas: initial;
    grid-template-rows: auto; }
  .i-am-all-statements-commands__associated-commands .i-am-statements__header {
    margin: 0; }
  .i-am-all-statements-commands__associated-commands .i-am-button {
    width: 50%;
    font-size: 1rem;
    height: 60px; }
  .i-am-all-statements-commands__associated-commands .section {
    position: relative;
    top: -10px; }
  .i-am-all-statements-commands__associated-commands .i-am-commands__title {
    font-size: 1.2rem; }
  .i-am-all-statements-commands__associated-commands .i-am-commands__number {
    height: 4rem;
    width: 4rem;
    bottom: -2rem;
    font-size: 1rem; }
  .i-am-all-statements-commands__associated-commands .i-am-faith-statement {
    grid-template-areas: ". faith-header ." "faith-body faith-body faith-body" ". logo .";
    margin-bottom: 0; }
  .i-am-all-statements-commands__associated-commands .i-am-faith-statement__header {
    background: #00FFFF;
    background: var(--main-color);
    width: 70%;
    justify-self: center;
    color: #000; }
  .i-am-all-statements-commands__associated-commands .i-am-faith-statement__body {
    padding: 40px; }
  .i-am-all-statements-commands__associated-commands .i-am-faith-statement__logo {
    grid-area: logo;
    background: #00FFFF;
    background: var(--main-color);
    align-self: center;
    justify-self: center;
    z-index: 10;
    position: relative;
    top: -10px;
    height: 60px;
    width: 60px; }
  .i-am-all-statements-commands__blurb {
    color: white;
    font-family: 'EB Garamond', serif;
    text-align: justify;
    font-size: 1.25rem;
    line-height: 1.85rem;
    text-shadow: 2px 4px 4px rgba(0,0,0,0.3);
    text-shadow: var(--shadow); }
  .i-am-all-statements-commands__statements {
    display: flex;
    flex-direction: column; }
  .i-am-all-statements-commands__label {
    letter-spacing: .3rem;
    font-family: Montserrat, sans-serif;
    font-family: var(--mont);
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px; }
  .i-am-all-statements-commands__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
    width: 100%;
    grid-template-rows: 1fr;
    grid-row-gap: 2.5rem;
    grid-column-gap: 8px; }
  .i-am-all-statements-commands__statement-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 2px; }
    .i-am-all-statements-commands__statement-container .i-am-button {
      padding: 0;
      width: 100%;
      height: 40px;
      font-size: .6rem; }

