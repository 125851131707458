
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css?family=Karla&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import './media-queries.scss';


:root {
  --main-color: #00FFFF;
  --mont: Montserrat, sans-serif;
  --karla: Karla, sans-serif;
  --garamond: EB, serif;
  --oswald: Oswald, serif;
  --shadow: 2px 4px 4px rgba(0,0,0,0.3);
}


body {
    background: #010101f7;
}

html, body, #root {
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
  @include screen-medium-max() {
    width: 100%;
  }
  &.modal-on {
    filter: brightness(0.2);
    pointer-events:  none;
    transition: all 0.2s linear;
  }
}

.i-am {
  background-position: center center;
  background-size: cover;
  transition: all .5s linear;
  -webkit-transform:translate3d(0,0,0);
  &--desktop {
    font-family: var(--mont);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    height: 100%;
    overflow-y: scroll;
  }

  &--mobile {
    font-family: var(--mont);
    display: grid;
    background-repeat: no-repeat;
    background-attachment: fixed;
  } 

  &--statements {
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    grid-template-areas: '. content .';
    align-items: stretch;
  }

  &__header-container {
    display: grid;
    grid-gap: 15px;
    align-items: center;
    grid-area: header;
  }
  

  &__body {
    font-family: 'EB Garamond', serif;
    text-align: justify;
    font-size: .8rem;
    grid-area: body;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    align-items: center;
    color: white;
    text-shadow: var(--shadow);

    h3 {
      font-weight: 400;
      margin:0;
      font-size: .9rem;
      text-align: center;
    }
    p {
      font-size: 1rem;
      margin: 0;
      line-height: 1.6rem;
    }
  }

  &__header-verse {
    font-family: var(--mont);
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.025rem;
    word-spacing: 0.2rem;
    line-height: 1.4rem;
    text-shadow: var(--shadow);
    margin: 0;
    text-align: center;
  }

  &__header-passage {
    font-family: var(--mont);
    font-weight: 300;
    color: var(--main-color);
    margin: 0;
    text-align: center;
    font-size: 0.6rem;
    letter-spacing: 0.15rem;
    word-spacing: 0.2rem;
    text-shadow: var(--shadow);
  }
  &__button-group {
    grid-area: buttons;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @include screen-medium-max() {
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    grid-row-gap: 20px;
    &--home {
      grid-template-columns: 15% 1fr 15%;
      grid-template-areas: ". . ." ". header ." ". body ." ". buttons ." ". . .";
      grid-template-rows: 5% 1fr 1fr 1fr 5%;
    }

    &--statements {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      justify-items: center;
      align-items: center;
    }

    &--commands {
      grid-template-columns: 8% 1fr 8%;
      grid-template-areas: ". section .";

      .i-am-commands__number {
        height: 26px;
        bottom: -13px;
        width: 26px;
      }

      .section {
        padding-bottom: 60px;
      }

      .section__header {
        color: #fff;
        margin-top: 20%;
        margin-bottom: 40px;
        font-size: 1.5rem;
      }
    }

    &__header-container {
      justify-items: center;
    }
    &__header-verse {
      font-size: .8rem;
      text-align: center;
      letter-spacing: 0.025rem;
      word-spacing: 0.2rem;
      line-height: 1.4rem;
      text-shadow: var(--shadow);
    }

    
  }

  @include screen-mobile-landscape() {
    &--statements {
      display: grid;
      grid-template-columns: 5% 1fr 5%;
      grid-template-areas: '. content .';
      align-items: stretch;

      .i-am-all-statements-commands__statement-container {
        grid-template-columns: repeat(4, 1fr);      

        .i-am-button {
          margin: 0;
        }
      }

      .i-am-all-statements-commands__associated-commands {
        .i-am-commands__title {
          font-size: .8rem;
        }
        .i-am-commands__number {
          height: 2rem;
          width: 2rem;
          bottom: -1rem;
          font-size: .8rem;
        }
      }
    }

   &__header-container {

    .i-am-logo {
      display: flex;
      height: 80px;
    }

    .i-am-button {
      width: auto;
      padding: 0 20px;
      height: 40px;
    }

     display: flex;
     flex-direction: column;
     height: 80vh;
     justify-content: space-evenly;
   }
  }

  @include screen-iphone-8-landscape() {
    &__header-container {
       height: 60vh;
     }
  }

  @include screen-iphone-8-plus-landscape() {
    &__header-container {
       height: 40vh;
     }
  }

  @include screen-large-min() {

    &__header-container {
      grid-gap: 40px;
      grid-template-areas: "header" "body" "button";
    }
    &__logo {
      display: flex;
      justify-content: center;
      @include firefox-only {
        width: 50%;
        margin: auto;
      }
    }

    .i-am-logo {
      height: 100%;
      width: 80%;
    }

    .i-am-button {
      margin: 0;
      width: auto;
      justify-self: center;
      padding: 0 40px;
      font-weight: 600;
    }
  }
}

.i-am-statements {
    display: flex;
    flex-direction: column;
    align-items: center;

 &__header {
   color: white;
  //  margin-top: 60px;
   display: flex;
   justify-content: center;
   font-weight: 300;
   letter-spacing: 0.25rem;
   word-spacing: 0.2rem;
   grid-area: header;
   .i-am-button {
    width: 250px;
    height: 50px;
   }
 }

 &.show-commands {
   display: grid;
   grid-row-gap: 20px;
   width: 100%;
   height: 100%;
   grid-template-columns: 22% 1fr 22%;
   grid-template-areas: "header header header" ". section ." "faith faith faith"
 }

 @include screen-medium-max() {
  &__header {
    margin-top: 20px;
  }
 }
 @include screen-mobile-landscape() {
  grid-area: content;
 }
}

.i-am-faith-statement {
  grid-area: faith;
  display: grid;
  grid-template-columns: 15% 1fr 15%;
  grid-template-areas: ". faith-header ." "faith-body faith-body faith-body";
  margin: 80px 0;

  &__header {
    background: var(--main-color);
    font-weight: 600;
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 5px;
    z-index: 1;
    height: 40px;
    font-family: var(--mont);
    grid-area: faith-header;
  }

  &__body {
    z-index: 2;
    grid-area: faith-body;
    background: #00000070;
    color: #fff;
    padding: 10%;
    font-size: .9rem;
    text-align: center;
    line-height: 2;
  }
}

.i-am-button {
  cursor: pointer;
  border: none;
  font-size: 0.8rem;
  font-family: var(--mont);
  font-weight: 100;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.15rem;
  word-spacing: 0.2rem;
  background: var(--main-color);
  color: black;
  position: relative;
  margin: 5px 0;

  .statement-box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &--transparent {
    color: white;
    background: transparent;
    border: 1px solid var(--main-color);
  }

  &--transparent-title {
    color: white;
    background: transparent;
    border: 1px solid var(--main-color);
    font-weight: 600;
    letter-spacing: 0.05rem;
    word-spacing: 0.1rem;

  }

  &--full-border {
    background: #00000080;
    font-weight: 600;
    border: 1px solid var(--main-color);
    color: white;
  }
}

.i-am-modal {
  color: #fff;
  z-index: 9999;

  &__container {
    width: 80vw;
    max-height: 70vh;
    border: 1px solid var(--main-color);
    padding: 20px 20px 40px 20px;
    background: #00000090;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__passage {
    font-family: var(--mont);
    font-weight: 300;
    font-size: .8rem;
    line-height: 1.5rem;
    overflow: auto;
    letter-spacing: 0.025rem;
  }

  &__passage-reference {
    text-transform: uppercase;
    padding: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    color: #000;
    position: absolute;
    bottom: -10px;
    width: 80%;
    height: 20px;
    font-family: var(--karla);
    letter-spacing: 0.2rem;
    font-size: .9rem;
  }

  @include screen-large-min() {
    &__container {
      width: 50vw;
    }

    &__passage-reference {
      width: 40%;
    }
  }
}

.section {
  grid-area: section;
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
    width: 100%;
    grid-template-rows: 1fr;
    grid-row-gap: 2.5rem;
    grid-column-gap: 8px;
  }

  &__header {
    display: flex;
    justify-content: center;
    letter-spacing: .3rem;
  }
}

.i-am-commands {
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #00000070;
  color: #fff;
  font-size: 12px;
  padding: 1rem 1rem 2rem 1rem;

  &.selected {
    .i-am-commands__number {
      color: white;
      border: 1px solid var(--main-color);
      background: #000;
    }
  }

  &:before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(4 / 3 * 100%);
  }

  &__title {
    display: flex;
    align-items: flex-start;
    padding-top: 3px;
    text-align: center;
    justify-content: center;
    line-height: 1.4;
  }

  &__number {
    position: absolute;
    bottom: -20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #000;
    background: var(--main-color);
    justify-content: center;
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 700;
  }

  &.fortynine-commands:before {
    padding-bottom: calc(4 / 2.4 * 100%);
  }
}

.i-am-logo__path {
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-width:.82px;
}

.statement-box__path {
  fill:none;
  stroke:#0ff;
  stroke-miterlimit:10;
  stroke-width:2.25px;
}


.fab-button {
  cursor: pointer;
  box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
  background: var(--main-color);
  border-radius: 50%;
  border: 1px solid #000;
  height: 50px;
  width: 50px;
  position: fixed;
  z-index: 500;
  bottom: 40px;
  right: 40px;

  @include screen-large-min() {
    bottom: 80px;
    right: 80px;
    height: 60px;
    width: 60px;
  }
}

.background-image {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  z-index: -1;

&__container {
 height: 100%;
 width: 100%;
 position: relative;
}

.img-responsive {
  height: 100%;
  width: 100%;
  object-fit: cover;

  &:nth-child(2n) {
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
  }

  &.picture-exit {

    opacity: 1;
  }
  /* ending EXIT animation */
  &.picture-exit-active {
    opacity: 0;

    transition: opacity 1000ms ease-in-out;
  }
}
}

.i-am-all-statements-commands {
  grid-row-gap: 40px;
  display: grid;
  grid-area: content;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: "blurb" "statements" "commands";
  padding: 60px 0;
  
  &.current-statement {
    grid-template-areas: initial;
    grid-template-rows: auto;
  }

  &__associated-commands {
    .i-am-statements__header {
      margin: 0;
    }

    .i-am-button {
      width: 50%;
      font-size: 1rem;
      height: 60px;
    }

    .section {
      position: relative;
      top: -10px;
    }

    .i-am-commands__title {
      font-size: 1.2rem;
    }

    .i-am-commands__number {
      height: 4rem;
      width: 4rem;
      bottom: -2rem;
      font-size: 1rem;
    }

    .i-am-faith-statement {
      grid-template-areas: ". faith-header ."
      "faith-body faith-body faith-body" ". logo .";
      margin-bottom: 0;
    }

    .i-am-faith-statement__header {
      background: var(--main-color);
      width: 70%;
      justify-self: center;
      color: #000;      
    }

    .i-am-faith-statement__body {
      padding:40px;
    }

    .i-am-faith-statement__logo {
      grid-area: logo;
      background: var(--main-color);
      align-self: center;
      justify-self: center;
      z-index: 10;
      position: relative;
      top: -10px;
      height: 60px;
      width: 60px;
    }
  }

  &__blurb {
    color: white;
    font-family: 'EB Garamond', serif;
    text-align: justify;
    font-size: 1.25rem;
    line-height: 1.85rem;
    text-shadow: var(--shadow);
  }

  &__statements {
    display: flex;
    flex-direction: column;
  }

  &__label {
    letter-spacing: .3rem;
    font-family: var(--mont);
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
    width: 100%;
    grid-template-rows: 1fr;
    grid-row-gap: 2.5rem;
    grid-column-gap: 8px;
  }

  &__statement-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 2px;
    .i-am-button {
      padding: 0;
      width: 100%;
      height: 40px;
      font-size: .6rem;
    }

    // .i-am-button:before {
    //   content: '';
    //   display: block;
    //   height: 0;
    //   width: 0;
    //   padding-bottom: calc(4 / 3 * 100%);
    // }
  }
}