@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}

@mixin screen-medium-max() {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin screen-large-min() {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin screen-iphone-8-plus-landscape() {
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

@mixin screen-iphone-8-landscape() {
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin screen-ipad-landscape() {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    @content;
  }
}

@mixin screen-ipad-portrait() {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    @content;
  }
}

@mixin screen-ipad-pro-landscape() {
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}

@mixin screen-ipad-pro-10() {
  @media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin screen-ipad-pro-12() {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin screen-ipad-pro-portrait() {
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}

@mixin screen-iphone-x-landscape() {
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3)and (orientation: landscape) {
    @content;
  }
}

@mixin screen-iphone-xs-max-landscape() {
  @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
    @content;
  }
}

@mixin screen-mobile-portrait() {
  @media only screen and (min-device-width: 320px) and (max-device-width: 500px) and (orientation: portrait) {
    @content;
  }
}

@mixin screen-mobile-landscape() {
  @media only screen and (min-device-width: 320px) and (max-device-width: 896px) and (orientation: landscape) {
    @content;
  }
}

@mixin screen-mobile() {
  @media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
    @content;
  }
}

@mixin screen-ultra-wide() {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin screen-iphone-8-portrait() {
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    @content;
  }
}

@mixin screen-iphone-8-plus-portrait() {
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

@mixin screen-iphone-x-portrait() {
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    @content;
  }
}

@mixin screen-iphone-xr-portrait() {
  @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2) and (orientation: portrait) {
    @content;
  }
}

@mixin screen-iphone-xs-portrait() {
  @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3) and (orientation: portrait) {
    @content;
  }
}

@mixin screen-iphone-5-portrait() {
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
    @content;
  }
}